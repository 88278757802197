
import { DateTime } from "luxon";

export function formatDate(dateString) {
    var x = new Date(dateString);
    if (!isNaN(x)) {
        return x.toISOString().split('T')[0];
    }
    else {
        return "";
    }
}

export function formatDatetime(dateObj) {
    return dateObj.getFullYear() + '-' + ('0' + (dateObj.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObj.getDate()).slice(-2)
        + ' ' + ('0' + dateObj.getHours()).slice(-2)
        + ':' + ('0' + dateObj.getMinutes()).slice(-2);
}

export function gmtToLocal(dateString) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = DateTime.fromISO(dateString, { zone: "gmt" });
    const localDate = utcDate.setZone(timeZone);

    if (localDate.isValid) {
        return localDate.toFormat("yyyy-LL-dd'T'HH:mm:ss");
    }
    else {
        return null;
    }
     
}

export function localToGmt(dateString) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = DateTime.fromISO(dateString, { zone: timeZone });
    const utcDate = localDate.setZone("gmt");
   

    if (utcDate.isValid) {
        return utcDate.toFormat("yyyy-LL-dd'T'HH:mm:ss");
    }
    else {
        return null;
    }

}


export function calculateAge(dateObj) {
    var now = new Date();
    
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var yearDob = dateObj.getYear();
    var monthDob = dateObj.getMonth();
    var dateDob = dateObj.getDate();
    var age = {};

    var yearAge;
    var monthAge;
    var dateAge;

    var ageString;

    yearAge = yearNow - yearDob;

    if (monthNow >= monthDob)
        monthAge = monthNow - monthDob;
    else {
        yearAge--;
        monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob)
        dateAge = dateNow - dateDob;
    else {
        monthAge--;
        dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
        }
    }

    age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
    };

    if (age.years > 0)
        ageString = age.years + '歲';

    if (age.months > 0)
        ageString = ageString + age.months + '個月';

    return ageString;
}


export function calculateAgeJson(dateObj) {
    var now = new Date();

    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var yearDob = dateObj.getYear();
    var monthDob = dateObj.getMonth();
    var dateDob = dateObj.getDate();
    var age = {};

    var yearAge;
    var monthAge;
    var dateAge;

    yearAge = yearNow - yearDob;

    if (monthNow >= monthDob)
        monthAge = monthNow - monthDob;
    else {
        yearAge--;
        monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob)
        dateAge = dateNow - dateDob;
    else {
        monthAge--;
        dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
        }
    }

    age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
    };

    return age;
}